{
  "graphQL": {
    "httpURL": "https://graphql-server.cybus.io/graphql",
    "webSocketURL": "wss://graphql-server.cybus.io/subscriptions"
  },
  "expiration": {
    "expired": 0,
    "toExpireSoon": 84
  },
  "internationalization": {
    "locale": "en",
    "translations": {
      "ACCOUNT": "Account",
      "ACTIVE": "Active",
      "ADD_USER_GROUP_LABEL": "Add User Group to Role",
      "ADD_USER_GROUP_TITLE": "Grant User Group access to Role",
      "ADMIN_LICENSES_HEADER_TITLE": "Admin Licenses",
      "ADMIN_LICENSE_HEADER_TITLE": "Admin License",
      "ADMIN_ROLES_HEADER_TITLE": "Admin Roles",
      "ADMIN_ROLE_HEADER_TITLE": "Admin Role (formerly called \"Permission Groups\")",
      "ADMIN_USERS_TITLE": "Admin Users",
      "ADMIN_USER_GROUPS_HEADER_TITLE": "Admin User Groups",
      "ADMIN_USER_GROUP_HEADER_TITLE": "Admin User Group",
      "APPLIANCES_HEADER_TITLE": "Appliances",
      "APPLIANCES_IN_ACCOUNT": "Appliances: {value}",
      "APPLIANCES_NO_LONGER_SUPPORTED_1": "The page \"Appliances\" is no longer needed, as the Cybus Portal is now used only for managing your licenses. All steps needed for managing your licenses are now under the \"Licenses\" menu.",
      "APPLIANCES_NO_LONGER_SUPPORTED_2": "The previous distinction between \"Managed Appliances\" and \"Self-hosted Appliances\" has been dropped and there are now only \"Self-hosted Appliances\" anymore. This makes it unnecessary to keep the license management split between License and Appliance pages, hence the Appliances page has been removed. The text field \"Appliance Name\" has been moved to the respective license as \"License Description\" text field. Your data has been moved over there.",
      "APPLIANCES_REDIRECT_TEXT": "To generate a license key, or download the license file, or edit the license description text (formerly: Appliance Name), please go to the page ",
      "APPLIANCES_TITLE": "Appliances",
      "APPLIANCE_HEADER_TITLE": "Appliance",
      "APPLIANCE_HEALTH_LABEL": "{value,select,\n        Healthy {Healthy}\n        Updating {Updating}\n        Unhealthy {Unhealthy}\n        Unavailable {Unavailable}\n        other {Unexpected {value}}\n    }",
      "APPLIANCE_IS_ONLINE_LABEL": "{value,select,\n        true {Connected}\n        other {Offline}\n    }",
      "APPLIANCE_TITLE": "Appliance",
      "APPLICATION_TITLE": "Cybus Portal",
      "ARCHIVED": "Archived",
      "ASSIGN_LICENSE_TO": "Set license description (e.g. which machine the license is used for)",
      "ASSOCIATED_ACCOUNT": "Associated Account",
      "ASSOCIATED_LICENSE": "Associated License",
      "AUTOMATIC_LICENSE_RENEWAL": "Automatic license renewal",
      "AUTO_RENEWAL": "Auto Renewal",
      "BETA": "Beta",
      "BOOLEAN": "{value,select, true {Yes} other {No}}",
      "CANCEL": "Cancel",
      "CLOSE_ACTION": "Close",
      "CONFIRM": "Confirm",
      "CONTACT_SUMMARY": "Email: <strong>{email}</strong>{br}Telephone Number: <strong>{phone}</strong>",
      "COPY_OF": "Copy of {name}",
      "CREATE": "Create",
      "CREATED_AT": "Created At",
      "CREATE_LICENSE": "Create License",
      "CREATE_SELFHOSTED_APPLIANCE": "Create Appliance",
      "CURRENTLY_USED": "Currently Used",
      "CUSTOMER": "Customer",
      "DATE": "Date",
      "DELETE": "Delete",
      "DELETE_APPLIANCE": "Delete Appliance",
      "DELETE_LICENSE": "Delete license",
      "DELETE_LICENSES": "Delete License(s)",
      "DELETE_USER_GROUP_LABEL": "Delete User Group from Role",
      "DELETE_USER_GROUP_TITLE": "Revoke User Group access for Role",
      "DEPRECATED": "- deprecated",
      "DESCRIPTION": "Description",
      "DIRECTORY_FIELD_CONTROL_LABEL": "Use default directory",
      "DIRECTORY_LABEL": "Directory",
      "DOWNLOAD_ACTION": "Download",
      "DOWNLOAD_LICENSE_FILE_BUTTON_TEXT": "Download License File",
      "DOWNLOAD_LICENSE_FILE_DESCRIPTION": "To download the license file you have to provide a valid license key:",
      "DOWNLOAD_LICENSE_FILE_DIALOG_TITLE": "Download License File",
      "DOWNLOAD_LICENSE_FILE_KEY_INPUT_LABEL": "Connectware License Key",
      "DOWNLOAD_LICENSE_FILE_TOOLTIP_TITLE": "Download License File",
      "DOWNLOAD_LICENSE_FILE_USER_DESCRIPTION_1": "Manually downloading the license file is only necessary if the Connectware runs on a machine without internet access.",
      "DOWNLOAD_LICENSE_FILE_USER_DESCRIPTION_2": "Steps to manually validate your Connectware appliance:",
      "DOWNLOAD_LICENSE_FILE_USER_DESCRIPTION_3": "- Enter Connectware license key to download your license file.",
      "DOWNLOAD_LICENSE_FILE_USER_DESCRIPTION_4": "- Upload your license file into the Connectware using the Admin UI or REST/MQTT endpoints.",
      "DOWNLOAD_LICENSE_KEY_COPY_BUTTON_TEXT": "Copy License Key",
      "DOWNLOAD_LICENSE_KEY_DESCRIPTION": "Generating a license key will reset this license and revoke all previously issued keys.",
      "DOWNLOAD_LICENSE_KEY_DIALOG_TITLE": "Generate License Key",
      "DOWNLOAD_LICENSE_KEY_DOWNLOAD_BUTTON_TEXT": "Download License Key",
      "DOWNLOAD_LICENSE_KEY_GENERATED_TEXT": "Here is your license key assigned to <strong>{licenseDescription}</strong>. You will not be able to view this license key again once you close this window, so be sure to record it.",
      "DOWNLOAD_LICENSE_KEY_GENERATE_BUTTON_TEXT": "Generate License Key",
      "DOWNLOAD_LICENSE_KEY_TOOLTIP_TITLE": "Download License Key",
      "DOWNLOAD_LICENSE_KEY_USER_DESCRIPTION_1": "- Generate Connectware license key (see left)",
      "DOWNLOAD_LICENSE_KEY_USER_DESCRIPTION_2": "- Enter Connectware license key when prompted in the Connectware installer script.",
      "DUPLICATE": "Duplicate",
      "DUPLICATE_LICENSE": "Duplicate license",
      "EDIT_AWS_ACTION": "Edit in AWS Cognito",
      "EDIT_LICENSES": "Edit License(s)",
      "EDIT_SELECTED_LICENSE": "Edit selected license(s)",
      "EMAIL": "Email",
      "ENABLED": "{value,select, true {Enabled} other {Disabled}}",
      "ERROR_DESCRIPTION": "Error <strong>{type}</strong> <strong>{message}</strong>",
      "ERROR_DESCRIPTION_NOT_SET": "No description set!",
      "EXPIRATION": "Expiration",
      "EXPIRATION_DATE": "Expiration Date",
      "FEATURES_TITLE": "Features",
      "GRANT": "Grant",
      "GROUP_LABEL": "Group Name",
      "HOME_TITLE": "Home",
      "LDAP": "LDAP",
      "LICENSES": "Licenses",
      "LICENSES_DELETING_DESCRIPTION": "Are you sure you want to delete this License(s)?",
      "LICENSES_EDITING_DESCRIPTION": "Updating the field below for the following license(s):",
      "LICENSES_HEADER_TITLE": "Licenses",
      "LICENSES_IN_ACCOUNT": "Licenses in Account: {value}",
      "LICENSES_TITLE": "Licenses",
      "LICENSE_CLASS": "License Class",
      "LICENSE_DESCRIPTION_CUSTOM_FILTER_OPTIONS_RENDER": "{value, select, false {Not currently assigned} other {Assigned to: {value}}}",
      "LICENSE_DESCRIPTION_NOT_SET": "No license key",
      "LICENSE_DESCRIPTION_VALUE": "{value,select, false{(No license key created yet)} other {{value}}}",
      "LICENSE_EXPIRATION_LABEL": "{expiration,select,\n            expired {License has expired}\n            toExpireSoon {{autoRenewal,select,\n                true {License is about to expire and will be renewed automatically}\n                other {License is about to expire}}\n            }\n        }",
      "LICENSE_HEADER_TITLE": "License",
      "LICENSE_KEY": "License Key",
      "LICENSE_NAME": "License Name",
      "LICENSE_TITLE": "Connectware License",
      "LOADING": "Loading...",
      "LOGOUT": "Logout",
      "LOGO_TEXT_DESCRIPTION": "Cybus Logo",
      "MANAGE_LICENSES_TITLE": "Manage Licenses",
      "MANAGE_LICENSE_TITLE": "Manage License",
      "MANAGE_ROLES_SUBTITLE": "formerly 'Permission Groups'",
      "MANAGE_ROLES_TITLE": "Manage Roles",
      "MANAGE_USERS_SUBTITLE": "Cognito Users",
      "MANAGE_USERS_TITLE": "Show Users",
      "MANAGE_USER_GROUPS_NO_MATCH": "No users have been assigned to this user group so far!",
      "MANAGE_USER_GROUPS_SUBTITLE": "Cognito User Groups",
      "MANAGE_USER_GROUPS_TITLE": "Show User Groups",
      "MANAGE_USER_GROUP_TITLE": "{type} of User Group: {name}",
      "MESSAGES_PER_DAY": "Messages per day",
      "METRICS_TABLE_TITLE": "Metrics Overview",
      "METRICS_TITLE": "Metrics",
      "METRICS_UPLOAD_DIALOG_BUTTON": "Upload metrics",
      "METRICS_UPLOAD_DIALOG_SELECT_FILE": "Select a metrics (csv) file",
      "METRICS_UPLOAD_DIALOG_SELECT_FILE_INFO_TEXT": "Please ensure that the columns 'License ID' and 'Encrypted' are provided",
      "METRICS_UPLOAD_DIALOG_CONFIRM_LICENSE": "Please confirm the metrics belong to this license: {licenseName}",
      "METRICS_UPLOAD_TITLE": "Upload Metrics",
      "NAME": "Name",
      "NOT_AVAILABLE": "{type, select, short {N/A} other {Not Available}}",
      "NO_ALLOWED_DIRECTORIES": "Usergroup has no roles or permissions assigned! Please contact cybus.",
      "NO_LICENSES": "There are no more licenses available. Please contact our sales team at {emailLink}",
      "NUMBER_OF_CONNECTIONS": "Number of Connections",
      "NUMBER_OF_DATAPOINTS": "Number of Datapoints",
      "NUMBER_OF_DUPLICATES": "Number of Duplicates",
      "NUMBER_OF_ENDPOINTS": "Number of Endpoints",
      "NUMBER_OF_MAPPINGS": "Number of Mappings",
      "NUMBER_OF_PROTOCOLS": "Number of Protocols",
      "NUMBER_OF_ROLES": "Number of Roles",
      "NUMBER_OF_SERVICES": "Number of Services",
      "NUMBER_OF_USERS": "Number of Users",
      "NUMBER_OF_X_LABEL": "Number of {label}",
      "OPEN_DRAWER": "Open drawer",
      "PERMISSIONS": "Permissions",
      "PREFERENCES_LANGUAGE": "Language: ",
      "PORTAL_ERROR_EMTPY_FILE": "Empty {format} file!",
      "PORTAL_ERROR_MISSING_COLUMN": "Selected file is missing the required \"{column}\" column!",
      "PORTAL_ERROR_STATE_NULL": "Edited state null",
      "PREFERENCES_TITLE": "Preferences",
      "PROTOCOLS_TITLE": "Protocols",
      "PURCHASED": "{value,select, true {Purchased} other {Not Purchased}}",
      "RECEIVED": "Received on",
      "REQUEST_LICENSE": "Request License",
      "REQUEST_LICENSE_TIPS": "Online requests for Licenses are currently disabled. Please call and ask to talk to our Sales Team.",
      "RESOURCES_TITLE": "Resources",
      "REVOKE": "Revoke",
      "ROLES": "Roles",
      "ROLES_TABLE_NO_MATCH": "This user group has not been assigned to a role so far!",
      "SAVE": "Save",
      "SELECTED_APPLIANCES": "Selected Appliances",
      "SERVICES_CATALOG_HEADER_TITLE": "Service Catalog",
      "SERVICES_CATALOG_TITLE": "Service Catalog",
      "SERVICES_IN_CATALOG": "Services in Catalog: {value}",
      "SERVICE_DETAILS_ADDITIONAL_INFO_DIRECTORY": "Permission URL folder",
      "SERVICE_DETAILS_ADDITIONAL_INFO_DOWNLOADS": "Downloads",
      "SERVICE_DETAILS_ADDITIONAL_INFO_FILENAME": "Filename",
      "SERVICE_DETAILS_ADDITIONAL_INFO_HOMEPAGE": "Homepage",
      "SERVICE_DETAILS_ADDITIONAL_INFO_OPTIONAL_TITLE": "Optional",
      "SERVICE_DETAILS_ADDITIONAL_INFO_PROVIDER": "Provider",
      "SERVICE_DETAILS_ADDITIONAL_INFO_PUBLICLY_OFFERED": "Publicly offered",
      "SERVICE_DETAILS_ADDITIONAL_INFO_PUBLICLY_OFFERED_ARIA_LABEL": "publicly available",
      "SERVICE_DETAILS_ADDITIONAL_INFO_RELEASED_AT": "Released on",
      "SERVICE_DETAILS_ADDITIONAL_INFO_TEMPLATE": "Use as Template",
      "SERVICE_DETAILS_ADDITIONAL_INFO_TEMPLATE_ARIA_LABEL": "available as template",
      "SERVICE_DETAILS_ADDITIONAL_INFO_TITLE": " Additional Info",
      "SERVICE_DETAILS_ADDITIONAL_INFO_UPDATED_AT": "Updated on",
      "SERVICE_DETAILS_ADDITIONAL_INFO_VERSION": "Version",
      "SERVICE_DETAILS_DESCRIPTION": "Description",
      "SERVICE_DETAILS_EXPANDED": "{expanded, select, true {Hide} other {Show}} file content",
      "SERVICE_DETAILS_TITLE": "Service Details",
      "SHOW_LICENSE": "Show License",
      "STATUS": "Status",
      "SUMMARY_TITLE": "Summary",
      "TABLE_LABEL_ID": "ID",
      "TYPE_OF_LICENSE": "Type of License",
      "UPDATE": "Update",
      "UPDATE_USER_LICENSE_DIALOG_TITLE": "Edit license: {name}",
      "UPLOAD_DIALOG_CUSTOM_DIRECTORY_LABEL": "Create new Directory",
      "UPLOAD_DIALOG_DESCRIPTION": "Description for the Service",
      "UPLOAD_DIALOG_DESCRIPTION_PLACEHOLDER": "Fancy service description",
      "UPLOAD_DIALOG_NAME": "Name of the Service",
      "UPLOAD_DIALOG_NAME_PLACEHOLDER": "Enter name or use name from file",
      "UPLOAD_DIALOG_SELECT_CATEGORY": "Select a category for the Service",
      "UPLOAD_DIALOG_SELECT_CATEGORY_LABEL": "Select Category",
      "UPLOAD_DIALOG_SELECT_DIRECTORY": "Select or create new folder for permission URL",
      "UPLOAD_DIALOG_SELECT_DIRECTORY_LABEL": "Select folder",
      "UPLOAD_DIALOG_SELECT_FILE": "Select File",
      "UPLOAD_DIALOG_SELECT_PUBLICLY_AVAILABLE": "Publicly available",
      "UPLOAD_DIALOG_SELECT_TEMPLATE": "Use as Template",
      "UPLOAD_METRICS": "Upload Metrics",
      "USERS": "Users",
      "USER_GROUPS_FORM_LABEL": "User Groups",
      "USER_GROUPS_TITLE": "User Groups",
      "VALUE": "Value",
      "WELCOME_TITLE": "Welcome to the Cybus Portal",
      "YES_NO": "{value, select, true {Yes} other {No}}"
    }
  },
  "cognito": {
    "redirectUrl": "https://cybus-portal-production.auth.eu-central-1.amazoncognito.com/login?response_type=token&client_id=21dk4hmpfg3i4pmqs4demsvp0o&redirect_uri=https%3A%2F%2Fportal.cybus.io%2Flogin",
    "userPoolBaseUrl": "https://eu-central-1.console.aws.amazon.com/cognito/users?region=eu-central-1#/pool/eu-central-1_lYOB50ktG"
  },
  "appVersion": "1.2.4",
  "contactInformation": {
    "email": "sales@cybus.io",
    "phone": "(+49) 40 - 22 85 86 85 0"
  },
  "links": {
    "imageInstaller": "https://portal-artifacts-cybusportalartifacts-kp7ffcfoz7xc.s3.eu-central-1.amazonaws.com/balena.img.zip",
    "installerOVA": "https://portal-artifacts-cybusportalartifacts-kp7ffcfoz7xc.s3.eu-central-1.amazonaws.com/190408-connectware-utils_0-1-0-vmware-vmx11-sha1.ova",
    "playbook": "https://portal-artifacts-cybusportalartifacts-kp7ffcfoz7xc.s3.eu-central-1.amazonaws.com/ova-import-esxi.pdf"
  }
}